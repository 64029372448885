import { useState, useEffect, useContext, ChangeEvent } from "react"
import { useHttp } from "../hooks/http.hook"
import { AuthContext } from "../context/authContext"
import { LoadingBar } from "./loadingBar"
import { ArticleTable } from "./articleTable"
import { CasesTable } from "./casesTable"
import ExportToXlsx from '../services/export_to_xlsx'
import { NavLink } from "react-router-dom"
import { useLocation } from "react-router-dom"
import ReactModal from 'react-modal'
import { cas, unit, leadtime_converter, purity, quantity, alwaysCopy  } from '../services/instruments'

ReactModal.setAppElement('#root')

export const SearchComponent = (props: {page :string}) => {
  const location = useLocation()
  const { state } = location
  const auth = useContext(AuthContext)
  const { loading, request, error, clearError } = useHttp()

  const setAlgorithms = () => {
    const algorithms:[] = []
    state.articles.forEach((item:string)=> {
      state.cases.forEach((cas: {sku: string, manufacturer: string}) => {
        if (item === cas.sku.toLocaleLowerCase()) {
          // @ts-ignore
          algorithms.push(cas.manufacturer)
        }
      })
    })
    // @ts-ignore
    return [...new Set(algorithms)]
  }

  const [form, setForm] = useState({ data: state ? state.articles.join(' ') : "" })
  const [data, setData] = useState([])
  const [selectedData, setSelectedData] = useState([])
  const [algoritmMethods, setAlgoritmMethods] = useState(state && state.cases.length !==0 ? setAlgorithms() : ["Macklin"])
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [content, setContent] = useState('danger')
  const [isCopy, setIsCopy] = useState(false)
  const [dangerArticles, setDangerArticles] = useState([])
  const [selectedDangerArticles, setSelectedDangerArticles] = useState([])
  const [formules, setFormules] = useState([])
  const [dangers, setDangers] = useState([])

  useEffect(()=> {
    // Отрефакторить хук запроса и возвращать весь реквест, чтобы проверять код ответа для логаута вместо проверки на текст.
    if(error === 'Ошибка получения токена доступа') {
      auth.logout()
    }
  }, [error, auth])

  useEffect(() => {
    // @ts-ignore
    if (dangers.length > 0) new ExportToXlsx(dangers).exportToFile()
  }, [dangers])

  const setAllDangers = () => {
    request('/products/dangers', "POST", {}, {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth.token}`
    }).then((req)=> {
      setDangers(req)
    }).catch(()=>{
      setDangers([])
      console.log("ERROR to Set dangers")
    })
  }

  const changeHandler = (e: ChangeEvent) => {
    if (e.target instanceof HTMLInputElement) {
      setForm({...form, [e.target?.name]: e.target?.value})
    }
  }

  const getDataHandler = async (e: any) => {
    if(e.key === 'Enter'){
      clearError()
      setData([])
      setSelectedData([])
      setDangerArticles([])
      const url = props.page === 'cas' ? '/products/cases' : '/products'
      const data = await request(url, "POST", {articles: form.data, methods: algoritmMethods}, {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth.token}`
      }).catch(()=>{
        setData([])
        setSelectedData([])
        setDangerArticles([])
      })

      if (data) {
        setData(data)
        if (props.page === 'sku'){
          // @ts-ignore
          setSelectedData([...new Set(form.data.toLocaleLowerCase().split(' ').filter((item) => item.indexOf('-') >= 0))])
          // @ts-ignore
          setDangerArticles([...new Set(data.map((item:any) => {
            if (item.products.length === 0) return undefined
            if (item.products[0].danger) return item.key
          }))].filter(Boolean))
        }
      }
    }
  }

  const getXlsx = () => {
    if (selectedData.length === 0) return true

    const result:[] = []
    data.forEach((item: {products: []})=>{
      const products = item.products.filter((product:{sku: string})=>{
        // @ts-ignore
        return selectedData.includes(product.sku.toLocaleLowerCase())
      })
      Array.prototype.push.apply(result, products)
    })
    new ExportToXlsx(result).exportToFile()
  }

  const selectAll = () => {
    // @ts-ignore
    setSelectedData(data.map((item: {products: []})=>{return item.products.map((product: {sku: string})=>{return product.sku.toLocaleLowerCase()})}).flat())
  }

  const removeAll = () => {
    setSelectedData([])
  }

  const changeAlgoritmMethods = (e: ChangeEvent) => {
    if (e.target instanceof HTMLInputElement) {
      if (e.target.checked) {
        setAlgoritmMethods([...algoritmMethods, e.target.value])
      } else {
        const value = e.target.value
        setAlgoritmMethods(algoritmMethods.filter((item:string)=>{return item !== value}))
      }
    }
  }

  const generateXlsxData = () => {
    setIsOpenModal(false)
    getXlsx()
  }

  const copyToBuffer = () => {
    alwaysCopy(formules.join('\n'))
    setIsCopy(true)
    setTimeout(()=> setIsCopy(false), 3000)
  }

  const checkDangerProducts = () => {
    setContent('danger')
    setSelectedDangerArticles([])
    if (dangerArticles.length > 0) {
      const arr:[] = []
      for (const article of dangerArticles) {
        for (const selected of selectedData) {
          // @ts-ignore
          if (selected.indexOf(article) >=0) arr.push(article.toLocaleUpperCase())
        }
      }
      setSelectedDangerArticles(arr)

      if (arr.length > 0) return setIsOpenModal(true)
      getXlsx()
    } else {
      getXlsx()
    }
  }

  const generateDatForBuffer = () => {
    if (selectedData.length === 0) return []

    const parseData:[] = []
    const result:[] = []

    data.forEach((item: {products: []})=>{
      const products = item.products.filter((product:{sku: string})=>{
        // @ts-ignore
        return selectedData.includes(product.sku.toLocaleLowerCase())
      })
      Array.prototype.push.apply(parseData, products)
    })

    parseData.forEach((product) => {
      // @ts-ignore
      result.push(`${product.sku.toLocaleUpperCase()}∩${cas(product.cas)}∪${product.manufacturer.replace('CST', 'Cell Signaling Technology')}⊂Helen⊃${product.item}⊄${product.danger ? "Недоступно к заказу" : leadtime_converter(product.leadtime, product.manufacturer)}⊅${product.danger ? "ЗАПРЕЩЕНО" : (product.price || '')}⊆${product.price_unit}⊇${purity(product.purity)}∏${product.shipping_temp}∐${quantity(product.quantity.toLocaleLowerCase())}⨒${unit(product.unit)}⨗`)
    })

    return result
  }

  const openFormulaModal = () => {
    setContent('formula')
    const res = generateDatForBuffer()
    alwaysCopy(res.join('\n'))
    setFormules(res)
    setIsOpenModal(true)
  }

  const closeModal = () => {
    setIsOpenModal(false)
    setIsCopy(false)
  }

  return(
    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
      <h4>{props.page.toLocaleUpperCase()}</h4>
      <div className="input-field" style={{width: '75%', marginTop: '0', display: 'flex', flexDirection: 'column'}}>
        <input
          type="text"
          name="data"
          className="validate"
          placeholder="Введите данные для поиска"
          onChange={changeHandler}
          onKeyDown={getDataHandler}
          value={form.data}
          autoComplete="off"
          disabled={loading}
          style={{width: '80%', alignSelf: 'center'}}
        />
        <div style={{marginTop: '1.5em'}}>
          {props.page !== 'sku' &&
            <div style={{marginBottom: '2em'}}>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                {['Acmec', 'Aladdin', 'Macklin', 'MedChemExpress', 'Sigma', 'TargetMol'].map((item) =>
                  <label style={{display: 'flex', marginRight: '2em'}} key={"cas" + item}>
                    <input type="checkbox" className="filled-in" value={item} checked={algoritmMethods.includes(item)} onChange={changeAlgoritmMethods} disabled={loading} />
                    <span>{item}</span>
                  </label>
                )}
              </div>
            </div>
          }
          {
            props.page === 'sku' &&
            <div>
              <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '1em'}}>
                {['Abcam', 'Abclonal', 'Acmec', 'Aladdin', 'Bioec', 'CST', 'Heqi'].map((item) =>
                  <label style={{display: 'flex', marginRight: '2em'}} key={"sku" + item}>
                    <input type="checkbox" className="filled-in" value={item} checked={algoritmMethods.includes(item)} onChange={changeAlgoritmMethods} disabled={loading} />
                    <span>{item}</span>
                  </label>
                )}
              </div>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                {['Macklin', 'MedChemExpress', 'Miltenyi Biotec', 'Promega', 'Sigma', 'Synthware', 'TargetMol', 'Thermofisher'].map((item) =>
                  <label style={{display: 'flex', marginRight: '2em'}} key={"sku" + item}>
                    <input type="checkbox" className="filled-in" value={item} checked={algoritmMethods.includes(item)} onChange={changeAlgoritmMethods} disabled={loading} />
                    <span>{item}</span>
                  </label>
                )}
              </div>
            </div>
          }
        </div>
      </div>
      <div style={{width: "95%", marginTop: "3em", display: 'flex'}}>
        { props.page === 'sku' && <button className="waves-effect waves-light btn" onClick={setAllDangers} style={{marginRight: '2em'}}>Выгрузить опасные объекты</button> }
        { data.length > 0 && <button className="waves-effect waves-light btn" style={{marginRight: '2em'}} onClick={selectAll}>Выбрать все</button> }
        { data.length > 0 && <button className="waves-effect waves-light btn" style={{marginRight: '2em'}} onClick={removeAll}>Сбросить все</button> }
        { data.length > 0 && selectedData.length > 0 && props.page === 'sku' && <button className="waves-effect waves-light btn" onClick={checkDangerProducts} style={{marginRight: '2em'}}>Выгрузить в xlsx</button> }
        { data.length > 0 && selectedData.length > 0 && props.page === 'sku' && <button className="waves-effect waves-light btn" onClick={openFormulaModal}>Сформировать формулы</button> }
        { data.length > 0 && selectedData.length > 0 && props.page === 'cas' &&
          <button className="waves-effect waves-light btn">
            <NavLink className="white-text center-align" style={{padding: '1.5em', width: '10em'}} to="/" state={{articles: selectedData, cases: data.map((item: {products: []})=>{return item.products}).flat()}}><b>Поиск по артикулам</b></NavLink>
          </button>
        }
      </div>
      <div style={{width: "95%", marginTop: "4em"}}>
        {
          error && <div className="red-text center-align">{error}</div>
        }
        {
          loading && <LoadingBar />
        }
      </div>
      <div style={{width: '95%', marginBottom: '4em'}}>
        { (props.page === 'sku') &&
          data.map((item, index)=>(
            <ArticleTable key={index} article={item} selectedData={selectedData} setSelectedData={setSelectedData} />
          ))
        }

        { props.page === 'cas' &&
          data.map((item, index)=>(
            <CasesTable key={index} cas={item} selectedData={selectedData} setSelectedData={setSelectedData} />
          ))
        }
      </div>
      <ReactModal isOpen={isOpenModal} id="articleModal" style={{overlay: {zIndex: 100000}, content: {width: "60%", height: "60%", margin: "auto", display: "flex", flexDirection: "column"}}}>
        { content === 'danger' &&
          <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
            <h1 style={{marginTop: 0, textAlign: 'center'}}>Внимание!</h1>
            <h5 style={{width: "100%", textAlign: 'center'}}>Вы пытаетесь выгрузить артикулы, которые являются опасными и запрещены к продаже и импорту:</h5>
            <h5 style={{flexGrow: 1, textAlign: 'center', width: '100%', marginBottom: "2em"}}>{selectedDangerArticles.join(',')}</h5>
            <div style={{display: "flex", width: '100%', justifyContent: 'space-between'}}>
              <button className="waves-effect waves-light btn" onClick={generateXlsxData}>Продолжить</button>
              <button className="waves-effect waves-light btn" onClick={closeModal}>Закрыть и проверить таблицу</button>
            </div>
          </div>
        }
        { content === 'formula' &&
          <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
            <h3 style={{marginTop: 0, marginBottom: 0, textAlign: 'center'}}>Формулы по выбранным артикулам</h3>
            <h5 style={{marginBottom: "1em", textAlign: 'center'}}>Формулы автоматически скопированы, если нет, нажмите кнопку "Скопировать повторно" или скопируйте вручную</h5>
            <div style={{flexGrow: 1, width: '100%', marginBottom: "2em"}}>
              { formules.map((item) =>
                <div style={{marginBottom: '0.5em'}} key={item}>{item}</div>
              )}
            </div>
            <div style={{display: "flex", width: '100%', justifyContent: 'space-between'}}>
              <button className="waves-effect waves-light btn" onClick={copyToBuffer}>{!isCopy ? 'Скопировать повторно' : 'Скопировано!'}</button>
              <button className="waves-effect waves-light btn" onClick={closeModal}>Закрыть</button>
            </div>
          </div>
        }
      </ReactModal>
    </div>
  )
}

